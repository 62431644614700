import { createAction } from 'redux-actions'

import * as constants from './constants'

export const login = createAction(constants.LOGIN)
export const loginPending = createAction(constants.LOGIN_PENDING)
export const loginSuccess = createAction(constants.LOGIN_SUCCESS)
export const loginFailed = createAction(constants.LOGIN_FAILED)

export const signup = createAction(constants.SIGNUP)
export const signupPending = createAction(constants.SIGNUP_PENDING)
export const signupSuccess = createAction(constants.SIGNUP_SUCCESS)
export const signupFailed = createAction(constants.SIGNUP_FAILED)

export const fetchUser = createAction(constants.FETCH_USER)
export const fetchUserPending = createAction(constants.FETCH_USER_PENDING)
export const fetchUserSuccess = createAction(constants.FETCH_USER_SUCCESS)
export const fetchUserFailed = createAction(constants.FETCH_USER_FAILED)
