import axios from 'axios'
import Cookies from 'js-cookie'

export const createProduct = product => {
  return axios.post('http://localhost:5000/products', {}, {
    headers: {
      'Authorization': `Bearer ${Cookies.get('vendorsheets_auth')}`,
    },
  })
}

export const fetchProduct = id => {
  return axios.get(`http://localhost:5000/products/${id}/nested`, {
    headers: {
      'Authorization': `Bearer ${Cookies.get('vendorsheets_auth')}`,
    },
  })
}

export const updateProductBase = (id, base) => {
  return axios.put(`http://localhost:5000/products/${id}`, base, {
    headers: {
      'Authorization': `Bearer ${Cookies.get('vendorsheets_auth')}`,
    },
  })
}

export const updateProductDimensions = (id, dimensions) => {
  return axios.put(`http://localhost:5000/products/${id}/dimensions`, dimensions, {
    headers: {
      'Authorization': `Bearer ${Cookies.get('vendorsheets_auth')}`,
    },
  })
}

export const updateProductCertifications = (id, certifications) => {
  return axios.put(`http://localhost:5000/products/${id}/certifications`, certifications, {
    headers: {
      'Authorization': `Bearer ${Cookies.get('vendorsheets_auth')}`,
    },
  })
}

export const updateProductContact = (id, contact) => {
  return axios.put(`http://localhost:5000/products/${id}/contact`, contact, {
    headers: {
      'Authorization': `Bearer ${Cookies.get('vendorsheets_auth')}`,
    },
  })
}

export const fetchProductList = () => {
  return axios.get('http://localhost:5000/products', {
    headers: {
      'Authorization': `Bearer ${Cookies.get('vendorsheets_auth')}`,
    },
  })
}
