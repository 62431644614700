import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import * as actions from '../../redux/products/actions'
import * as selectors from '../../redux/products/selectors'

const Product = () => {
  let { id } = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.fetchProduct(id))
  }, [dispatch, id])

  const product = useSelector(selectors.getDraft)

  const onSaveClick = () => dispatch(actions.updateProduct())

  if (!product) return null

  return (
    <div>
      <label>
        upc
        <input
          value={product.upc}
          type="number"
          min={12}
          max={12}
      />
      </label>
      <label>
        name
        <input
          value={product.name}
          type="text"
          onChange={event => dispatch(actions.setProductBaseField({ field: 'name', value: event.target.value }))}
        />
      </label>
      <label>
        brand
        <input
          value={product.brand}
          type="text"
          onChange={event => dispatch(actions.setProductBaseField({ field: 'brand', value: event.target.value }))}
        />
      </label>
      <label>
        category
        <input value={product.category}/>
      </label>
      <label>
        exclusivity
        <input value={product.exclusivity}/>
      </label>
      <label>
        launch date
        <input value={product.launch_date}/>
      </label>
      <label>
        regional upc
        <input value={product.regional_identifier_upc}/>
      </label>
      <label>
        max cost dist cents
        <input value={product.max_delivered_case_cost_to_distributor_cents}/>
      </label>
      <label>
        max delivered case cost centrs
        <input value={product.max_delivered_case_cost_to_distributor_cents}/>
      </label>
      <label>
        retail price
        <input value={product.suggested_retail_price_cents}/>
      </label>
      <label>
        dist pref name
        <input value={product.distributor_preference_name}/>
      </label>
      <label>
        dist pref vin
        <input value={product.distributor_preference_vin}/>
      </label>
      <label>
        dist pref cost effective date
        <input value={product.distributor_preference_cost_effective_date}/>
      </label>
      <label>
        promo support program summary
        <input value={product.promotional_support_program_summary}/>
      </label>
      <label>
        support summary
        <input value={product.demo_support_program_summary}/>
      </label>
      <label>
        is line support ext
        <input value={product.is_line_extension}/>
      </label>
      <label>
        line ext upc
        <input value={product.line_extension_upc}/>
      </label>
      <button onClick={onSaveClick}>save</button>
    </div>
  )
}

export default Product
