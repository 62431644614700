import { pipe, prop, omit } from 'ramda'

const getRoot = prop('products')

const getProductsById = pipe(getRoot, prop('productsById'))
export const getProduct = (state, props) => getProductsById(state)[props.id]

export const getDraft = pipe(getRoot, prop('draft'))
export const getDraftId = pipe(getDraft, prop('id'))
export const getProductBase = pipe(getDraft, omit(['dimensions', 'certifications', 'contact']))
export const getProductDimensions = pipe(getDraft, prop('dimensions'))
export const getProductCertifications = pipe(getDraft, prop('certifications'))
export const getProductContact = pipe(getDraft, prop('contact'))

export const getProductBaseUpdated = pipe(getRoot, prop('productBaseUpdated'))
export const getProductDimensionsUpdated = pipe(getRoot, prop('productDimensionsUpdated'))
export const getProductCertificationsUpdated = pipe(getRoot, prop('productCertificationsUpdated'))
export const getProductContactUpdated = pipe(getRoot, prop('productContactUpdated'))

export const getProductList = pipe(getProductsById, Object.values)
