import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Card, Tabs, Tab, Button, InputGroup, Intent } from '@blueprintjs/core'
import validator from 'validator'

import * as actions from '../../redux/users/actions'
import * as selectors from '../../redux/users/selectors'

import './style.css'

const Mode = {
  SIGNUP: 'SIGNUP',
  LOGIN: 'LOGIN',
}

const ModeCopy = {
  [Mode.SIGNUP]: 'Sign Up',
  [Mode.LOGIN]: 'Log In',
}

const Login = () => {
  const dispatch = useDispatch()

  const loginLoading = useSelector(selectors.getLoginLoading)
  const signupLoading = useSelector(selectors.getSignupLoading)

  const [mode, setMode] = useState(Mode.LOGIN)
  const handleModeChange = mode => setMode(mode)

  const [email, setEmail] = useState('')
  const handleEmailChange = event => setEmail(event.target.value)
  const isEmailValid = email.length && validator.isEmail(email)

  const [password, setPassword] = useState('')
  const handlePasswordChange = event => setPassword(event.target.value)
  const [showPassword, setShowPassword] = useState(false)
  const handleLockClick = () => setShowPassword(!showPassword)

  const lockButton = (
    <Tooltip
      interactionKind="hover"
      content={`${showPassword ? 'Hide' : 'Show'} Password`}
    >
      <Button
        icon={showPassword ? 'unlock' : 'lock'}
        intent={Intent.WARNING}
        minimal={true}
        onClick={handleLockClick}
      />
    </Tooltip>
  )

  const handleSubmit = event => {
    event.preventDefault()

    const action = mode === Mode.LOGIN ? actions.login : actions.signup
    dispatch(action({ email, password }))
  }

  const tabClassName = 'login-mode-tab w-50 tc'

  return (
    <div className="flex justify-center pa5">
      <Card>
        <form onSubmit={handleSubmit}>
          <Tabs
            id="mode-tabs"
            className="mb3 login-mode-tabs"
            large
            onChange={handleModeChange}
            selectedTabId={mode}
          >
            <Tab
              className={tabClassName}
              id={Mode.LOGIN}
              title={ModeCopy[Mode.LOGIN]}
            />
            <Tab
              className={tabClassName}
              id={Mode.SIGNUP}
              title={ModeCopy[Mode.SIGNUP]}
            />
          </Tabs>
          <InputGroup
            className="mb2"
            large
            value={email}
            onChange={handleEmailChange}
            leftIcon="person"
            placeholder="Email"
          />
          <InputGroup
            className="mb3"
            leftIcon="key"
            large
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
            rightElement={lockButton}
            type={showPassword ? "text" : "password"}
          />
          <Button
            className="w-100"
            type="submit"
            intent="primary"
            large
            loading={loginLoading || signupLoading}
            disabled={!isEmailValid}
          >
            {mode === Mode.LOGIN ? ModeCopy[Mode.LOGIN] : ModeCopy[Mode.SIGNUP]}
          </Button>
        </form>
      </Card>
    </div>
  )
}

export default Login
