import { createAction } from 'redux-actions'

import * as constants from './constants'

export const createProduct = createAction(constants.CREATE_PRODUCT)
export const createProductPending = createAction(constants.CREATE_PRODUCT_PENDING)
export const createProductSuccess = createAction(constants.CREATE_PRODUCT_SUCCESS)
export const createProductFailed = createAction(constants.CREATE_PRODUCT_FAILED)

export const fetchProduct = createAction(constants.FETCH_PRODUCT)
export const fetchProductPending = createAction(constants.FETCH_PRODUCT_PENDING)
export const fetchProductSuccess = createAction(constants.FETCH_PRODUCT_SUCCESS)
export const fetchProductFailed = createAction(constants.FETCH_PRODUCT_FAILED)

export const updateProduct = createAction(constants.UPDATE_PRODUCT)

export const updateProductBase = createAction(constants.UPDATE_PRODUCT_BASE)
export const updateProductBasePending = createAction(constants.UPDATE_PRODUCT_BASE_PENDING)
export const updateProductBaseSuccess = createAction(constants.UPDATE_PRODUCT_BASE_SUCCESS)
export const updateProductBaseFailed = createAction(constants.UPDATE_PRODUCT_BASE_FAILED)

export const updateProductDimensions = createAction(constants.UPDATE_PRODUCT_DIMENSIONS)
export const updateProductDimensionsPending = createAction(constants.UPDATE_PRODUCT_DIMENSIONS_PENDING)
export const updateProductDimensionsSuccess = createAction(constants.UPDATE_PRODUCT_DIMENSIONS_SUCCESS)
export const updateProductDimensionsFailed = createAction(constants.UPDATE_PRODUCT_DIMENSIONS_FAILED)

export const updateProductCertifications = createAction(constants.UPDATE_PRODUCT_CERTIFICATIONS)
export const updateProductCertificationsPending = createAction(constants.UPDATE_PRODUCT_CERTIFICATIONS_PENDING)
export const updateProductCertificationsSuccess = createAction(constants.UPDATE_PRODUCT_CERTIFICATIONS_SUCCESS)
export const updateProductCertificationsFailed = createAction(constants.UPDATE_PRODUCT_CERTIFICATIONS_FAILED)

export const updateProductContact = createAction(constants.UPDATE_PRODUCT_CONTACT)
export const updateProductContactPending = createAction(constants.UPDATE_PRODUCT_CONTACT_PENDING)
export const updateProductContactSuccess = createAction(constants.UPDATE_PRODUCT_CONTACT_SUCCESS)
export const updateProductContactFailed = createAction(constants.UPDATE_PRODUCT_CONTACT_FAILED)

export const fetchProductList = createAction(constants.FETCH_PRODUCT_LIST)
export const fetchProductListPending = createAction(constants.FETCH_PRODUCT_LIST_PENDING)
export const fetchProductListSuccess = createAction(constants.FETCH_PRODUCT_LIST_SUCCESS)
export const fetchProductListFailed = createAction(constants.FETCH_PRODUCT_LIST_FAILED)

export const setProductBaseField = createAction(constants.SET_PRODUCT_BASE_FIELD)
export const setProductDimensionsField = createAction(constants.SET_PRODUCT_DIMENSIONS_FIELD)
export const setProductCertificationsField = createAction(constants.SET_PRODUCT_CERTIFICATIONS_FIELD)
export const setProductContactField = createAction(constants.SET_PRODUCT_CONTACT_FIELD)
