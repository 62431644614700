const makeConstant = constant => `users/${constant}`

export const LOGIN = makeConstant('LOGIN')
export const LOGIN_PENDING = makeConstant('LOGIN_PENDING')
export const LOGIN_SUCCESS = makeConstant('LOGIN_SUCCESS')
export const LOGIN_FAILED = makeConstant('LOGIN_FAILED')

export const SIGNUP = makeConstant('SIGNUP')
export const SIGNUP_PENDING = makeConstant('SIGNUP_PENDING')
export const SIGNUP_SUCCESS = makeConstant('SIGNUP_SUCCESS')
export const SIGNUP_FAILED = makeConstant('SIGNUP_FAILED')

export const FETCH_USER = makeConstant('FETCH_USER')
export const FETCH_USER_PENDING = makeConstant('FETCH_USER_PENDING')
export const FETCH_USER_SUCCESS = makeConstant('FETCH_USER_SUCCESS')
export const FETCH_USER_FAILED = makeConstant('FETCH_USER_FAILED')
