import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from './layouts/Login'
import ProductList from './layouts/ProductList'
import Product from './layouts/Product'

import './App.css'

const App = () => {
  return (
    <Switch>
      <Route path="/login" component={Login}/>
      <Route path="/products" exact component={ProductList}/>
      <Route path="/products/:id" component={Product}/>
    </Switch>
  )
}

export default App
