import { ofType, combineEpics } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  switchMap,
  mergeMap,
  startWith,
  pluck,
  map,
  tap,
  ignoreElements,
  catchError,
} from 'rxjs/operators'
import Cookies from 'js-cookie'

import * as api from './api'
import * as actions from './actions'

const loginEpic = action$ => action$.pipe(
  ofType(actions.login.toString()),
  pluck('payload'),
  switchMap(({ email, password }) => {
    return from(api.login(email, password)).pipe(
      pluck('data'),
      map(actions.loginSuccess),
      startWith(actions.loginPending()),
      catchError(() => of(actions.loginFailed())),
    )
  }),
)

const loginSuccessEpic = action$ => action$.pipe(
  ofType(actions.loginSuccess.toString()),
  pluck('payload'),
  tap(({ access_token }) => Cookies.set('vendorsheets_auth', access_token)),
  ignoreElements(),
)

const signupEpic = action$ => action$.pipe(
  ofType(actions.signup.toString()),
  pluck('payload'),
  mergeMap(({ email, password }) => {
    return from(api.signup(email, password)).pipe(
      pluck('data'),
      map(data => actions.signupSuccess({ ...data, password })),
      startWith(actions.signupPending()),
      catchError(() => of(actions.signupFailed())),
    )
  }),
)

const signupSuccessEpic = action$ => action$.pipe(
  ofType(actions.signupSuccess.toString()),
  pluck('payload'),
  map(actions.login),
)

const fetchUserEpic = action$ => action$.pipe(
  ofType(actions.fetchUser.toString()),
  switchMap(() => from(api.fetchUser()).pipe(
    pluck('data'),
    map(actions.fetchUserSuccess),
    startWith(actions.fetchUserPending()),
    catchError(() => of(actions.fetchUserFailed())),
  )),
)

export default combineEpics(
  loginEpic,
  loginSuccessEpic,
  signupEpic,
  signupSuccessEpic,
  fetchUserEpic,
)
