import { prop, pipe, equals } from 'ramda'

import * as actions from './actions'

const getRoot = prop('users')

const getLoginStatus = pipe(getRoot, prop('loginStatus'))
export const getLoginLoading = pipe(getLoginStatus, equals(actions.loginPending.toString()))

const getSignupStatus = pipe(getRoot, prop('signupStatus'))
export const getSignupLoading = pipe(getSignupStatus, equals(actions.signupPending.toString()))
