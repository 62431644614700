import { combineReducers } from 'redux'
import { combineActions } from 'redux-actions'

import { handleActions } from '../utils'

import * as actions from './actions'

const user = handleActions({
  [actions.fetchUserSuccess]: (state, action) => action.payload,
}, false)

const loginStatus = handleActions({
  [combineActions(
    actions.loginPending,
    actions.loginSuccess,
    actions.loginFailed,
  )]: (state, action) => action.type,
}, false)

const signupStatus = handleActions({
  [combineActions(
    actions.signupPending,
    actions.signupSuccess,
    actions.signupFailed,
  )]: (state, action) => action.type,
}, false)

export default combineReducers({
  user,
  loginStatus,
  signupStatus,
})
