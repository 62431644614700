import { combineReducers } from 'redux'

import { handleActions } from '../utils'

import * as actions from './actions'

const productsById = handleActions({
  [actions.createProductSuccess]: (state, action) => {
    state[action.payload.id] = action.payload
    return state
  },
  [actions.fetchProductListSuccess]: (state, action) => {
    action.payload.products.forEach(product => {
      state[product.id] = product
    })
    return state
  },
}, {})

const draft = handleActions({
  [actions.fetchProductSuccess]: (state, action) => action.payload,
  [actions.setProductBaseField]: (state, action) => {
    state[action.payload.field] = action.payload.value
    return state
  },
}, false)

const productBaseUpdated = handleActions({
  [actions.setProductBaseField]: () => true,
  [actions.updateProductBase]: () => false,
}, false)

const productDimensionsUpdated = handleActions({
  [actions.setProductDimensionsField]: () => true,
  [actions.updateProductDimensions]: () => false,
}, false)

const productCertificationsUpdated = handleActions({
  [actions.setProductCertificationsField]: () => true,
  [actions.updateProductCertifications]: () => false,
}, false)

const productContactUpdated = handleActions({
  [actions.setProductContactField]: () => true,
  [actions.updateProductContact]: () => false,
}, false)

export default combineReducers({
  productsById,
  draft,
  productBaseUpdated,
  productDimensionsUpdated,
  productCertificationsUpdated,
  productContactUpdated,
})
