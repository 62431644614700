const makeConstant = constant => `products/${constant}`

export const CREATE_PRODUCT = makeConstant('CREATE_PRODUCT')
export const CREATE_PRODUCT_PENDING = makeConstant('CREATE_PRODUCT_PENDING')
export const CREATE_PRODUCT_SUCCESS = makeConstant('CREATE_PRODUCT_SUCCESS')
export const CREATE_PRODUCT_FAILED = makeConstant('CREATE_PRODUCT_FAILED')

export const FETCH_PRODUCT = makeConstant('FETCH_PRODUCT')
export const FETCH_PRODUCT_PENDING = makeConstant('FETCH_PRODUCT_PENDING')
export const FETCH_PRODUCT_SUCCESS = makeConstant('FETCH_PRODUCT_SUCCESS')
export const FETCH_PRODUCT_FAILED = makeConstant('FETCH_PRODUCT_FAILED')

export const UPDATE_PRODUCT = makeConstant('UPDATE_PRODUCT')

export const UPDATE_PRODUCT_BASE = makeConstant('UPDATE_PRODUCT_BASE')
export const UPDATE_PRODUCT_BASE_PENDING = makeConstant('UPDATE_PRODUCT_BASE_PENDING')
export const UPDATE_PRODUCT_BASE_SUCCESS = makeConstant('UPDATE_PRODUCT_BASE_SUCCESS')
export const UPDATE_PRODUCT_BASE_FAILED = makeConstant('UPDATE_PRODUCT_BASE_FAILED')

export const UPDATE_PRODUCT_DIMENSIONS = makeConstant('UPDATE_PRODUCT_DIMENSIONS')
export const UPDATE_PRODUCT_DIMENSIONS_PENDING = makeConstant('UPDATE_PRODUCT_DIMENSIONS_PENDING')
export const UPDATE_PRODUCT_DIMENSIONS_SUCCESS = makeConstant('UPDATE_PRODUCT_DIMENSIONS_SUCCESS')
export const UPDATE_PRODUCT_DIMENSIONS_FAILED = makeConstant('UPDATE_PRODUCT_DIMENSIONS_FAILED')

export const UPDATE_PRODUCT_CERTIFICATIONS = makeConstant('UPDATE_PRODUCT_CERTIFICATIONS')
export const UPDATE_PRODUCT_CERTIFICATIONS_PENDING = makeConstant('UPDATE_PRODUCT_CERTIFICATIONS_PENDING')
export const UPDATE_PRODUCT_CERTIFICATIONS_SUCCESS = makeConstant('UPDATE_PRODUCT_CERTIFICATIONS_SUCCESS')
export const UPDATE_PRODUCT_CERTIFICATIONS_FAILED = makeConstant('UPDATE_PRODUCT_CERTIFICATIONS_FAILED')

export const UPDATE_PRODUCT_CONTACT = makeConstant('UPDATE_PRODUCT_CONTACT')
export const UPDATE_PRODUCT_CONTACT_PENDING = makeConstant('UPDATE_PRODUCT_CONTACT_PENDING')
export const UPDATE_PRODUCT_CONTACT_SUCCESS = makeConstant('UPDATE_PRODUCT_CONTACT_SUCCESS')
export const UPDATE_PRODUCT_CONTACT_FAILED = makeConstant('UPDATE_PRODUCT_CONTACT_FAILED')

export const FETCH_PRODUCT_LIST = makeConstant('FETCH_PRODUCT_LIST')
export const FETCH_PRODUCT_LIST_PENDING = makeConstant('FETCH_PRODUCT_LIST_PENDING')
export const FETCH_PRODUCT_LIST_SUCCESS = makeConstant('FETCH_PRODUCT_LIST_SUCCESS')
export const FETCH_PRODUCT_LIST_FAILED = makeConstant('FETCH_PRODUCT_LIST_FAILED')

export const SET_PRODUCT_BASE_FIELD = makeConstant('SET_PRODUCT_BASE_FIELD')
export const SET_PRODUCT_DIMENSIONS_FIELD = makeConstant('SET_PRODUCT_DIMENSIONS_FIELD')
export const SET_PRODUCT_CERTIFICATIONS_FIELD = makeConstant('SET_PRODUCT_CERTIFICATIONS_FIELD')
export const SET_PRODUCT_CONTACT_FIELD = makeConstant('SET_PRODUCT_CONTACT_FIELD')
