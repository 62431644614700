import axios from 'axios'
import Cookies from 'js-cookie'

export const login = (email, password) => {
  return axios.post('http://localhost:5000/users/login', {
    email,
    password,
  })
}

export const signup = (email, password) => {
  return axios.post('http://localhost:5000/users/signup', {
    email,
    password,
  })
}

export const fetchUser = () => {
  return axios.get('http://localhost:5000/users/me', {
    headers: {
      'Authorization': `Bearer ${Cookies.get('vendorsheets_auth')}`,
    },
  })
}
