import { ofType, combineEpics } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  mergeMap,
  startWith,
  pluck,
  map,
  catchError,
  filter,
} from 'rxjs/operators'

import {
  createProduct,
  fetchProduct,
  updateProductBase,
  updateProductDimensions,
  updateProductCertifications,
  updateProductContact,
  fetchProductList,
} from './api'
import * as actions from './actions'
import * as selectors from './selectors'

const createProductEpic = action$ => action$.pipe(
  ofType(actions.createProduct.toString()),
  pluck('payload'),
  mergeMap(product => from(createProduct(product)).pipe(
    pluck('data'),
    map(actions.createProductSuccess),
    startWith(actions.createProductPending()),
    catchError(() => of(actions.createProductFailed())),
  )),
)

const fetchProductEpic = action$ => action$.pipe(
  ofType(actions.fetchProduct.toString()),
  pluck('payload'),
  mergeMap(id => from(fetchProduct(id)).pipe(
    pluck('data'),
    map(actions.fetchProductSuccess),
    startWith(actions.fetchProductPending()),
    catchError(() => of(actions.fetchProductFailed())),
  )),
)


const updateProductBaseEpic = (action$, state$) => action$.pipe(
  ofType(actions.updateProduct.toString()),
  filter(() => selectors.getProductBaseUpdated(state$.value)),
  mergeMap(() => {
    const draftId = selectors.getDraftId(state$.value)
    const contact = selectors.getProductBase(state$.value)
    return from(updateProductBase(draftId, contact)).pipe(
      pluck('data'),
      map(actions.updateProductBaseSuccess),
      startWith(actions.updateProductBasePending()),
      catchError(() => of(actions.updateProductBaseFailed())),
    )
  }),
)

const updateProductDimensionsEpic = (action$, state$) => action$.pipe(
  ofType(actions.updateProduct.toString()),
  filter(() => selectors.getProductDimensionsUpdated(state$.value)),
  mergeMap(() => {
    const draftId = selectors.getDraftId(state$.value)
    const contact = selectors.getProductDimensions(state$.value)
    return from(updateProductDimensions(draftId, contact)).pipe(
      pluck('data'),
      map(actions.updateProductDimensionsSuccess),
      startWith(actions.updateProductDimensionsPending()),
      catchError(() => of(actions.updateProductDimensionsFailed())),
    )
  }),
)

const updateProductCertificationsEpic = (action$, state$) => action$.pipe(
  ofType(actions.updateProduct.toString()),
  filter(() => selectors.getProductCertificationsUpdated(state$.value)),
  mergeMap(() => {
    const draftId = selectors.getDraftId(state$.value)
    const contact = selectors.getProductCertifications(state$.value)
    return from(updateProductCertifications(draftId, contact)).pipe(
      pluck('data'),
      map(actions.updateProductCertificationsSuccess),
      startWith(actions.updateProductCertificationsPending()),
      catchError(() => of(actions.updateProductCertificationsFailed())),
    )
  }),
)

const updateProductContactEpic = (action$, state$) => action$.pipe(
  ofType(actions.updateProduct.toString()),
  filter(() => selectors.getProductContactUpdated(state$.value)),
  mergeMap(() => {
    const draftId = selectors.getDraftId(state$.value)
    const contact = selectors.getProductContact(state$.value)
    return from(updateProductContact(draftId, contact)).pipe(
      pluck('data'),
      map(actions.updateProductContactSuccess),
      startWith(actions.updateProductContactPending()),
      catchError(() => of(actions.updateProductContactFailed())),
    )
  }),
)

const fetchProductListEpic = action$ => action$.pipe(
  ofType(actions.fetchProductList.toString()),
  pluck('payload'),
  mergeMap(id => from(fetchProductList()).pipe(
    pluck('data'),
    map(actions.fetchProductListSuccess),
    startWith(actions.fetchProductListPending()),
    catchError(() => of(actions.fetchProductListFailed())),
  )),
)

export default combineEpics(
  createProductEpic,
  fetchProductEpic,
  updateProductBaseEpic,
  updateProductDimensionsEpic,
  updateProductCertificationsEpic,
  updateProductContactEpic,
  fetchProductListEpic,
)
