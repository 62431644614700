import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../../redux/products/actions'
import * as selectors from '../../redux/products/selectors'

const ProductList = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.fetchProductList())
  }, [dispatch])

  const productList = useSelector(selectors.getProductList)

  const onCreateClick = () => dispatch(actions.createProduct())

  return (
    <div>
      <button onClick={onCreateClick}>new product</button>
      {productList
        .map(product => (
          <div key={product.id}>
            <Link to={`/products/${product.id}`}>
              {product.id}
            </Link>
          </div>
        ))
      }
    </div>
  )
}

export default ProductList
